import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent, RouterModule } from '@angular/router';
import {
  CustomizeViewModule,
  LoggerService,
  PageLayoutModule,
  SecondaryNavigationModule,
} from '@vanguard/secure-site-components-lib';
import { LoggerCode } from '@models/logger';
import { filter, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgIf } from '@angular/common';
import { ToolbarComponent } from '@app/layout/toolbar/toolbar.component';
import { BalanceByDateService } from '@app/core/services/balance-by-date/balance-by-date.service';
import { Title } from '@angular/platform-browser';
import { PageUrlEnum } from '@models/page-url.enum';
import { content } from '@content/content';

@Component({
  standalone: true,
  selector: 'byd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterModule,
    PageLayoutModule,
    SecondaryNavigationModule,
    CustomizeViewModule,
    ToolbarComponent,
    NgIf,
  ],
})
export class AppComponent implements OnInit {
  private subscription: Subscription;
  public displayCustomizeViewLink: boolean;
  public urlPath: string = '';
  public header: string = '';
  public mainUrl: string = '';
  constructor(
    private router: Router,
    private title: Title,
    private logger: LoggerService,
    private balanceByDateService: BalanceByDateService,
  ) {}

  public ngOnInit(): void {
    // theoretically property "displayCustomizeViewLink" can be used later
    this.pageLoadProcessing();
    this.addSkipMain()
  }

  private pageLoadProcessing(): void {
    this.router.events
      .pipe(
        filter(
          (routerEvent: RouterEvent): routerEvent is NavigationEnd =>
            routerEvent instanceof NavigationEnd,
        ),
        map((navigationEndEvent: NavigationEnd) => {
          this.logEvent(navigationEndEvent.url);
          this.balanceByDateService.pageURL.next(navigationEndEvent.urlAfterRedirects);
          this.setTitle(navigationEndEvent.urlAfterRedirects);
          this.urlPath = this.router.url;
        }),
      )
      .subscribe();
  }

  setTitle(url: string): void {
    let title =
      url === `/${PageUrlEnum.BALANCE_BY_DATE}` ? content.pageTitles.BYD : content.pageTitles.BYE;
    this.title.setTitle(title);
    this.header =   url === `/${PageUrlEnum.BALANCE_BY_DATE}` ? content.pageTitles.BYD.split('|')[0] : content.pageTitles.BYE.split('|')[0];
  }


  private logEvent(url: string): void {
    this.logger.info({
      eventType: 'PageLoadSuccess',
      logCode: LoggerCode.PAGE_LOAD,
      message: url,
    });
  }



  public addSkipMain(): void {
    let mainContent: any = document.getElementsByTagName("vg-vgn-header")[0].shadowRoot.children[1].getElementsByTagName('a')[0].setAttribute('href', '#mainContent');
    this.mainUrl = mainContent;
  }

}
