<main id="app-main-content" role="main">
  <h1 class="text-hidden">{{header}}</h1>
  <ss-lib-page-layout>
    <div inner>
      <div class="row">
        <div class="col">
          <ss-lib-secondary-nav widgetActiveItem="InvestmentsTab" [displayPrintLink]="true"></ss-lib-secondary-nav>
        </div>
      </div>

      <ng-container class="row">
        <div class="col">
          <ss-lib-customize-view [displayCustomizeViewLink]="true"></ss-lib-customize-view>
        </div>
      </ng-container>

      <div class="row">
        <div class="col">
          <byd-toolbar [inputUrlPath]="urlPath" *ngIf="urlPath.length > 0" ></byd-toolbar>
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </ss-lib-page-layout>
</main>
